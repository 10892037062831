

















































































































































































































































import AdobeViewer from '@/components/AdobeViewer.vue'
import FileViewer from '@/components/FileViewer.vue'
import BaseInputText from '@/components/_base/BaseInputText.vue'
import TreeItemCard from '@/components/TreeItemCard.vue'
import {
  fileTreeItemList,
  IppeTree,
  ippeTree,
  IppeTreeItem,
} from '@/setup/ippe-files'
import { computed, defineComponent, ref } from '@vue/composition-api'

export type ListMode = 'grid' | 'list'

export default defineComponent({
  name: 'Files',

  components: {
    TreeItemCard,
    AdobeViewer,
    FileViewer,
  },

  setup() {
    const searchInput = ref<InstanceType<typeof BaseInputText> | null>(null)
    const currentTree = ref(ippeTree)
    const listMode = ref<ListMode>('grid')
    const searchBy = ref('')
    const searchMode = ref<'global' | 'local'>('global')
    const filteredContents = computed(() => {
      const list =
        searchBy.value && searchMode.value === 'global'
          ? fileTreeItemList
          : currentTree.value.contents
      return list.filter((item) =>
        item.name.toLowerCase().includes(searchBy.value.toLowerCase())
      )
    })
    const isFilteredContentsAllFiles = computed(() =>
      filteredContents.value.every((c) => c.type === 'file')
    )
    const shouldDisplayFilePreview = computed(
      () => isFilteredContentsAllFiles.value && listMode.value === 'list'
    )
    const currentFileTreeItem = ref<IppeTree | null>(null)
    const isModalOpen = ref(false)

    function onTreeItemSelect(item: IppeTreeItem) {
      if (item.type === 'file') {
        if (!shouldDisplayFilePreview.value) {
          isModalOpen.value = true
        }
        currentFileTreeItem.value = item
      } else {
        currentTree.value = item
        currentFileTreeItem.value = null
      }
    }

    function onGoBack() {
      searchBy.value = ''
      if (!currentTree.value.parentItem) return
      currentTree.value = currentTree.value.parentItem
      currentFileTreeItem.value = null
    }

    function onSearchInputEnter() {
      if (!searchInput.value) return
      const input = searchInput.value.$el as HTMLInputElement
      input.blur()
    }

    return {
      searchInput,
      currentTree,
      listMode,
      searchBy,
      searchMode,
      filteredContents,
      shouldDisplayFilePreview,
      onTreeItemSelect,
      onGoBack,
      currentFileTreeItem,
      isModalOpen,
      onSearchInputEnter,
    }
  },
})
