




import appConfig from '@/app.config.json'
import { defineComponent, onMounted, PropType, ref } from '@vue/composition-api'

let uuid = ref(0)

export default defineComponent({
  name: 'AdobeViewer',

  props: {
    name: {
      type: String,
      required: true,
    },

    src: {
      type: String,
      required: true,
    },

    embedMode: {
      type: String as PropType<
        'SIZED_CONTAINER' | 'IN_LINE' | 'LIGHT_BOX' | undefined
      >,
      default: undefined,
    },

    showAnnotationTools: {
      type: Boolean as PropType<boolean | undefined>,
      default: undefined,
    },

    enableFormFilling: {
      type: Boolean as PropType<boolean | undefined>,
      default: undefined,
    },

    showLeftHandPanel: {
      type: Boolean as PropType<boolean | undefined>,
      default: undefined,
    },

    showDownloadPDF: {
      type: Boolean as PropType<boolean | undefined>,
      default: undefined,
    },

    showPrintPDF: {
      type: Boolean as PropType<boolean | undefined>,
      default: undefined,
    },

    showFullScreen: {
      type: Boolean as PropType<boolean | undefined>,
      default: undefined,
    },

    showPageControls: {
      type: Boolean as PropType<boolean | undefined>,
      default: undefined,
    },

    dockPageControls: {
      type: Boolean as PropType<boolean | undefined>,
      default: undefined,
    },

    defaultViewMode: {
      type: String as PropType<'FIT_PAGE' | 'FIT_WIDTH' | undefined>,
      default: undefined,
    },
  },

  emits: {
    success: null,
  },

  setup(props, context) {
    const elementId = ref(`adobe-dc-view-${uuid.value++}`)

    onMounted(() => {
      const adobeDCView = new (window as any).AdobeDC.View({
        clientId:
          process.env.NODE_ENV === 'production'
            ? appConfig.adobe.pdfEmbedApi.production.apiKey
            : appConfig.adobe.pdfEmbedApi.test.apiKey,
        divId: elementId.value,
      })

      adobeDCView
        .previewFile(
          {
            content: { location: { url: props.src } },
            metaData: { fileName: props.name },
          },
          {
            embedMode: props.embedMode,
            ...(props.showAnnotationTools
              ? { showAnnotationTools: props.showAnnotationTools }
              : undefined),
            ...(props.enableFormFilling
              ? { enableFormFilling: props.enableFormFilling }
              : undefined),
            ...(props.showLeftHandPanel
              ? { showLeftHandPanel: props.showLeftHandPanel }
              : undefined),
            ...(props.showDownloadPDF
              ? { showDownloadPDF: props.showDownloadPDF }
              : undefined),
            ...(props.showPrintPDF
              ? { showPrintPDF: props.showPrintPDF }
              : undefined),
            ...(props.showFullScreen
              ? { showFullScreen: props.showFullScreen }
              : undefined),
            ...(props.showPageControls
              ? { showPageControls: props.showPageControls }
              : undefined),
            ...(props.dockPageControls
              ? { dockPageControls: props.dockPageControls }
              : undefined),
            ...(props.defaultViewMode
              ? { defaultViewMode: props.defaultViewMode }
              : undefined),
          }
        )
        .then(() => {
          context.emit('success')
        })
    })

    return {
      elementId,
    }
  },
})
