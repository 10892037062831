var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BaseBox',{class:[
    'flex items-center',
    _vm.listMode === 'grid'
      ? 'justify-center text-center flex-col space-y-3'
      : 'space-x-3' ]},[(_vm.type === 'folder' || (_vm.type === 'file' && _vm.listMode === 'grid'))?_c('BaseIcon',{class:_vm.type === 'folder'
        ? _vm.listMode === 'grid'
          ? 'text-6xl'
          : 'text-3xl'
        : 'text-8xl',attrs:{"name":_vm.type === 'folder' ? 'folder' : 'file',"source":"custom"}}):_vm._e(),_c('BaseBox',{staticClass:"grid"},[_c('BaseBox',{class:_vm.type === 'file' ? 'truncate' : ''},[_c('BaseText',{class:[
          'text-secondary font-semibold',
          _vm.type === 'folder'
            ? _vm.listMode === 'grid'
              ? 'text-xl'
              : 'text-3xl'
            : _vm.listMode === 'grid'
            ? ''
            : 'text-xl' ]},[_vm._v(" "+_vm._s(_vm.name)+" ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }