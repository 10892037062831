










































import { IppeTreeItemType } from '@/setup/ippe-files'
import { ListMode } from '@/views/Files.vue'
import { defineComponent, PropType } from '@vue/composition-api'

export default defineComponent({
  name: 'TreeItemCard',

  props: {
    name: {
      type: String,
      required: true,
    },

    type: {
      type: String as PropType<IppeTreeItemType>,
      required: true,
    },

    listMode: {
      type: String as PropType<ListMode>,
      default: 'grid' as ListMode,
    },
  },
})
