




















































import AdobeViewer from '@/components/AdobeViewer.vue'
import { useWindowKeyboardListener } from '@/composition/windowKeyboardListener'
import { computed, defineComponent, PropType } from '@vue/composition-api'

export default defineComponent({
  name: 'FileViewer',

  components: {
    AdobeViewer,
  },

  model: {
    prop: 'modelValue',
    event: 'update',
  },

  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },

    name: {
      type: String,
      default: '',
    },

    requireFile: {
      type: Function as PropType<(() => string) | null>,
      default: null,
    },

    closeOnBackdropClick: {
      type: Boolean,
      default: true,
    },

    closeWithEscapeKey: {
      type: Boolean,
      default: true,
    },
  },

  emits: {
    update: null,
  },

  setup(props, context) {
    const hasFooter = computed(() => Boolean(context.slots.footer))

    if (props.closeWithEscapeKey) {
      useWindowKeyboardListener({
        key: 'Escape',
        handler: (evt) => {
          if (
            evt &&
            evt.target &&
            ['INPUT', 'TEXTAREA'].includes((evt.target as HTMLElement).nodeName)
          )
            return

          close()
        },
      })
    }

    function onBackdropClick() {
      if (!props.closeOnBackdropClick) return
      close()
    }

    function close() {
      context.emit('update', false)
    }

    return {
      hasFooter,
      onBackdropClick,
      close,
    }
  },
})
