var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BaseBox',{staticClass:"relative h-full md:overflow-hidden"},[_c('BaseBox',{staticClass:"flex flex-col h-full space-y-20 px-4 py-12 lg:px-12"},[_c('BaseBox',{staticClass:"grid gap-4 md:gap-16 md:grid-cols-10"},[_c('BaseBox',{class:['md:col-span-3', _vm.currentTree.parentItem ? '' : 'invisible']},[_c('BaseLink',{staticClass:"inline-block py-3 cursor-pointer",on:{"click":_vm.onGoBack}},[_c('BaseBox',{staticClass:"flex space-x-4 text-gray-400 text-xl"},[_c('BaseText',[_c('BaseIcon',{attrs:{"name":"left-c","source":"custom"}})],1),_c('BaseText',[_vm._v(" "+_vm._s(_vm.currentTree.name)+" ")])],1)],1)],1),_c('BaseBox',{staticClass:"md:col-span-5"},[_c('BaseBox',{staticClass:"flex flex-col space-y-3"},[_c('BaseBox',{staticClass:"flex-1"},[_c('BaseBox',{staticClass:"relative"},[_c('BaseInputText',{ref:"searchInput",staticClass:"pl-12",attrs:{"placeholder":"Search","type":"search"},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.onSearchInputEnter.apply(null, arguments)}},model:{value:(_vm.searchBy),callback:function ($$v) {_vm.searchBy=$$v},expression:"searchBy"}}),_c('BaseBox',{staticClass:"\n                  absolute\n                  top-0\n                  left-4\n                  h-full\n                  grid\n                  items-center\n                  pointer-events-none\n                "},[_c('BaseText',{staticClass:"text-gray-400 text-2xl"},[_c('BaseIcon',{attrs:{"name":"search","source":"custom"}})],1)],1)],1)],1),_c('BaseBox',{class:[
              'flex items-center space-x-3',
              _vm.searchBy && _vm.currentTree.type !== 'root' ? '' : 'invisible' ]},[_c('BaseBox',[_c('BaseText',{staticClass:"text-gray-500"},[_vm._v(" Search: ")])],1),_c('BaseBox',{class:[
                'flex items-center space-x-2 py-1 px-2 rounded-md',
                _vm.searchMode === 'global' ? 'bg-secondary-100' : '' ]},[_c('BaseInputRadio',{attrs:{"id":"search-global","model-value":_vm.searchMode,"name":"search-mode","value":"global"},on:{"update":function($event){_vm.searchMode = $event}}}),_c('BaseFieldLabel',{attrs:{"label":"Global","html-for":"search-global"}})],1),_c('BaseBox',{class:[
                'flex items-center space-x-2 py-1 px-2 rounded-md',
                _vm.searchMode === 'local' ? 'bg-secondary-100' : '' ]},[_c('BaseInputRadio',{attrs:{"id":"search-local","model-value":_vm.searchMode,"name":"search-mode","value":"local"},on:{"update":function($event){_vm.searchMode = $event}}}),_c('BaseFieldLabel',{attrs:{"label":_vm.currentTree.name,"html-for":"search-local"}})],1)],1)],1)],1),_c('BaseBox',{staticClass:"md:col-span-2 flex justify-end md:items-start"},[_c('BaseBox',{staticClass:"flex space-x-3 text-2xl"},[_c('BaseBox',{class:[
              'relative rounded-full py-1 px-2 bg-brand-app-card text-gray-400 focus-within:ring-2',
              _vm.listMode === 'grid'
                ? 'shadow-brand-icon-inset'
                : 'shadow-brand-icon cursor-pointer' ],attrs:{"tag":"label","title":"Grid","for":"list-mode-grid"}},[_c('BaseInputRadio',{staticClass:"\n                absolute\n                inset-0\n                pointer-events-none\n                w-full\n                h-full\n                opacity-0\n              ",attrs:{"id":"list-mode-grid","model-value":_vm.listMode,"name":"list-mode","value":"grid"},on:{"update":function($event){_vm.listMode = $event}}}),_c('BaseIcon',{staticClass:"pointer-events-none",attrs:{"name":"grid","source":"custom"}})],1),_c('BaseBox',{class:[
              'relative rounded-full py-1 px-2 bg-brand-app-card text-gray-400 focus-within:ring-2',
              _vm.listMode === 'list'
                ? 'shadow-brand-icon-inset'
                : 'shadow-brand-icon cursor-pointer' ],attrs:{"tag":"label","title":"List","for":"list-mode-list"}},[_c('BaseInputRadio',{staticClass:"\n                absolute\n                inset-0\n                pointer-events-none\n                w-full\n                h-full\n                opacity-0\n              ",attrs:{"id":"list-mode-list","model-value":_vm.listMode,"name":"list-mode","value":"list"},on:{"update":function($event){_vm.listMode = $event}}}),_c('BaseIcon',{staticClass:"pointer-events-none",attrs:{"name":"list","source":"custom"}})],1)],1)],1)],1),_c('BaseBox',{class:[
        'flex-1 grid content-start md:overflow-hidden',
        _vm.shouldDisplayFilePreview ? 'md:grid-cols-2 gap-4' : '' ]},[_c('BaseBox',{class:[
          'grid content-start md:overflow-auto md:pb-8 lg:px-16',
          _vm.shouldDisplayFilePreview ? '' : 'gap-12',
          _vm.listMode === 'grid'
            ? 'lg:gap-24 grid-cols-2 md:grid-cols-4 lg:grid-cols-6 justify-items-center'
            : '' ]},_vm._l((_vm.filteredContents),function(item,index){return _c('BaseBox',{key:item.id},[_c('BaseLink',{class:[
              'cursor-pointer',
              _vm.shouldDisplayFilePreview ? 'block' : 'inline-block' ],attrs:{"tabindex":"0"},on:{"click":function($event){return _vm.onTreeItemSelect(item)},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.onTreeItemSelect(item)}}},[_c('BaseBox',{class:[
                _vm.listMode === 'list' ? 'p-2 rounded-brand-input' : '',
                _vm.shouldDisplayFilePreview && index % 2 === 0 ? 'bg-white' : '' ]},[_c('TreeItemCard',{attrs:{"name":item.name,"type":item.type,"list-mode":_vm.listMode}})],1)],1)],1)}),1),(
          _vm.shouldDisplayFilePreview &&
          _vm.currentFileTreeItem &&
          _vm.currentFileTreeItem.requireFile
        )?_c('BaseBox',{staticClass:"grid gap-4 content-start h-full"},[_c('BaseBox',[_c('AdobeViewer',{key:_vm.currentFileTreeItem.id,staticClass:"w-full h-80 lg:h-96",attrs:{"name":_vm.currentFileTreeItem.name,"src":_vm.currentFileTreeItem.requireFile(),"embed-mode":"SIZED_CONTAINER"}})],1),_c('BaseBox',{staticClass:"truncate"},[_c('BaseLink',{staticClass:"text-secondary text-3xl cursor-pointer",attrs:{"tabindex":"0"},on:{"click":function($event){_vm.isModalOpen = true},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }_vm.isModalOpen = true}}},[_vm._v(" "+_vm._s(_vm.currentFileTreeItem.name)+" ")])],1)],1):_vm._e()],1)],1),_c('FileViewer',{attrs:{"name":_vm.currentFileTreeItem ? _vm.currentFileTreeItem.name : undefined,"require-file":_vm.currentFileTreeItem ? _vm.currentFileTreeItem.requireFile : undefined},model:{value:(_vm.isModalOpen),callback:function ($$v) {_vm.isModalOpen=$$v},expression:"isModalOpen"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }